<template>
	<v-row>
		<v-col cols="7">
			<v-card>
				<v-card-title primary-title>
					{{ materi.tipe }}
				</v-card-title>
				<v-card-text>
					<v-simple-table dense>
						<template v-slot:default>
							<tbody>
								<tr>
									<td style="width: 130px;"><b>Agenda</b></td>
									<td style="width: 10px;">:</td>
									<td>Hari ke {{ hari_ke }}, {{ formatDate(materi.tanggal) }}</td>
								</tr>
								<tr>
									<td><b>Nama</b></td>
									<td>:</td>
									<td>{{ materi.nama }}</td>
								</tr>
								<tr v-if="materi.tipe == 'EVALUASI NARASUMBER'">
									<td><b>Pemateri</b></td>
									<td>:</td>
									<td>{{ materi.nama_user }}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="5">
			<v-card>
				<v-card-text>
					<h2 style="text-transform: capitalize;">{{ pelatihan.nama }}</h2>
					<h3>Detail Stakeholder Pelatihan:</h3>
					<div v-html="pelatihan.detail_stakeholder"></div>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12">
			<v-card>
				<v-card-text>
					<h3>Daftar Pertanyaan</h3>
					<v-btn x-small color="primary" @click="formPertanyaan.dialog = true">Tambah</v-btn>
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<th>No</th>
									<th>Pertanyaan</th>
									<th>Aksi</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, key) in table.items" :key="item.id">
									<td>{{ key + 1 }}</td>
									<td>{{ item.pertanyaan }}</td>
									<td>
										<v-icon small color="error" @click="deletePertanyaanSaved(item)">
											{{ icons.mdiTrashCan }}
										</v-icon>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>

					<!-- dialog pertanyaan -->
					<v-layout row justify-center>
						<v-dialog v-model="formPertanyaan.dialog" max-width="800">
							<v-card>
								<v-card-title primary-title>
									Pertanyaan
								</v-card-title>
								<v-card-text>
									<v-simple-table dense>
										<template v-slot:default>
											<thead>
												<tr>
													<th>No</th>
													<th>Pertanyaan</th>
													<th>Aksi</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(item, key) in tablePertanyaan.items" :key="item.id">
													<td>{{ key + 1 }}</td>
													<td>{{ item.pertanyaan }}</td>
													<td>
														<v-checkbox v-model="tablePertanyaan.selected" label="Pilih"
															:value="item.id" dense></v-checkbox>
													</td>
												</tr>
											</tbody>
											<tfoot>
												<tr>
													<td colspan="2"></td>
													<td>
														<!-- <v-checkbox class="mt-4" v-model="tablePertanyaan.selectedAll"
															@click="selectAll()" label="Pilih Semua"></v-checkbox> -->
														<v-btn small class="my-1" color="primary"
															:loading="tablePertanyaan.save_loading"
															@click="savePertanyaan()">Simpan</v-btn>
													</td>
												</tr>
											</tfoot>
										</template>
									</v-simple-table>
								</v-card-text>
							</v-card>
						</v-dialog>
					</v-layout>
				</v-card-text>
			</v-card>

			<v-card class="mt-4">
				<v-card-text>
					<h3>Hasil Evaluasi</h3>
					<b>Jumlah Responden = {{ tableJawaban.jumlahResponden }}</b>
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<th>No</th>
									<th>Pertanyaan</th>
									<th>Nilai Rata-Rata</th>
									<th>Hasil</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, key) in tableJawaban.items" :key="item.id">
									<td>{{ key + 1 }}</td>
									<td>{{ item.pertanyaan }}</td>
									<td>{{ item.nilai_rata }}</td>
									<td>{{ item.hasil }}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
					<h3 class="mt-2">Komentar dan Saran</h3>
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<th>No</th>
									<th>Isi</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, key) in tableSaran.items" :key="item.id">
									<td>{{ key + 1 }}</td>
									<td>{{ item.saran }}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>

				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<style scoped>
table tr td {
	vertical-align: top;
}

.pdf {
	width: 100%;
	aspect-ratio: 4 / 3;
}

.pdf,
html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
}
</style>

<script>
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiEye, mdiPencil, mdiMagnify, mdiPlus, mdiTrashCan, mdiLock, mdiClose } from '@mdi/js'

export default {
	components: {
	},
	data() {
		return {
			icons: {
				mdiEye,
				mdiPencil,
				mdiMagnify, mdiPlus, mdiTrashCan, mdiLock, mdiClose
			},
			id_pelatihan: this.$route.params.id_pelatihan,
			id_materi: this.$route.params.id_materi,
			hari_ke: this.$route.params.hari_ke,
			pelatihan: {},
			materi: {
				tanggal: '',
				nama: '',
				deskripsi: '',
				jenis_tugas: '',
				nama_user: '',
				file_url: '',
			},
			formPertanyaan: {
				dialog: false,
				editedIndex: -1,
				edit: {
					id_materi: '',
					pertanyaan: '',
				},
				default: {
					id_materi: '',
					pertanyaan: '',
				}
			},
			table: {
				items: []
			},
			tablePertanyaan: {
				items: [],
				selected: [],
				selectedAll: false,
				save_loading: false,
			},
			tableJawaban: {
				items: [],
				jumlahResponden: 0,
			},
			tableSaran: {
				items: [],
			},
		}
	},
	watch: {
	},
	computed: {
	},
	created() {
		this.getPelatihan()
		this.getMateri()
		this.getPertanyaanSaved()
		this.getPertanyaan()
		this.getHasilEvaluasi()
	},
	methods: {
		getPelatihan() {
			const fmData = new FormData()
			fmData.append('id_pelatihan', this.id_pelatihan)
			axiosPostAuth('api/Pelatihan/getPelatihan', fmData)
				.then(response => {
					this.pelatihan = Object.assign({}, response.data)
				})
				.catch(error => {
					alert(error)
				})
		},
		getMateri() {
			const fmData = new FormData()
			fmData.append('id_materi', this.id_materi)
			fmData.append('id_pelatihan', this.id_pelatihan)
			axiosPostAuth('api/Pelatihan/getTugasPeserta', fmData)
				.then(response => {
					this.materi = Object.assign({}, response.data)

					const fmData = new FormData()
					// fmData.append('jenis', 'NARASUMBER')
					fmData.append('jenis', this.materi.tipe.replace('EVALUASI', ''))
					axiosPostAuth('api/Evaluasi/getMasterPertanyaan', fmData)
						.then(response => {
							this.tablePertanyaan.items = response.data
						})
						.catch(error => {
							alert(error)
						})
				})
				.catch(error => {
					alert(error)
				})
		},
		getPertanyaan() {
			// const fmData = new FormData()
			// fmData.append('jenis', 'NARASUMBER')
			// axiosPostAuth('api/Evaluasi/getMasterPertanyaan', fmData)
			// 	.then(response => {
			// 		this.tablePertanyaan.items = response.data
			// 	})
			// 	.catch(error => {
			// 		alert(error)
			// 	})
		},
		// lihatUploaded(item) {
		// 	this.formPertanyaan.edit = Object.assign({}, item)
		// 	this.formPertanyaan.dialog = true
		// },
		savePertanyaan() {
			const fmData = new FormData()
			fmData.append('id_materi', this.id_materi)
			fmData.append('id_pertanyaan', this.tablePertanyaan.selected)
			axiosPostAuth('api/Evaluasi/saveEvaluasiPemateriPertanyaan', fmData)
				.then(response => {
					if (response.code === 200) {
						this.$notify({
							type: 'success',
							title: 'Berhasil!',
							text: response.message,
						});
						this.getPertanyaanSaved()
						this.formPertanyaan.dialog = false
					}
					else {
						alert(response.message)
					}
				})
				.catch(error => {
					alert(error)
				})
		},
		getPertanyaanSaved() {
			const fmData = new FormData()
			fmData.append('id_materi', this.id_materi)
			axiosPostAuth('api/Evaluasi/getEvaluasiPemateriPertanyaan', fmData)
				.then(response => {
					this.table.items = response.data
				})
				.catch(error => {
					alert(error)
				})
		},
		deletePertanyaanSaved(item) {
			if (confirm("Apakah yakin akan dihapus?")) {
				const fmData = new FormData()
				fmData.append('id_pertanyaan', item.id)
				axiosPostAuth('api/Evaluasi/deleteEvaluasiPemateriPertanyaan', fmData)
					.then(response => {
						if (response.code == 200) {
							this.$notify({
								type: 'success',
								title: 'Berhasil!',
								text: response.message,
							});
							this.getPertanyaanSaved()
						} else {
							this.$notify({
								type: 'error',
								title: 'Gagal!',
								text: response.message,
							});
						}
					})
					.catch(error => {
						alert(error)
					})
			}
		},
		getHasilEvaluasi() {
			const fmData = new FormData()
			fmData.append('id_materi', this.id_materi)
			axiosPostAuth('api/Evaluasi/getEvaluasiPemateriHasilJawaban', fmData)
				.then(response => {
					this.tableJawaban.items = response.data
					this.tableSaran.items = response.sarans
					this.tableJawaban.jumlahResponden = response.total_responden
				})
				.catch(error => {
					alert(error)
				})
		},
	},
}
</script>